import axios from 'axios';

export const getExchangeRate = async (data) => {
  let responseData = {
    exchangeRate: null,
    error: null,
  };
  try {
    const response = await axios({
      method: 'post',
      url: process.env.GEMINI_EXCHANGE_RATES_ENDPOINT,
      data,
      headers: {
        Authorization: `Bearer ${process.env.GEMINI_RESOURCE_JWT}`,
      },
    });
    responseData = response.data;
  } catch (error) {
    responseData.error = error;
  }
  return responseData;
};

export default {
  path: '/api/get-exchange-rate',
  async handler(req, res) {
    const response = await getExchangeRate(req.query);
    res.send(response);
  },
};
